import Vue from "vue/dist/vue.esm";

import BootstrapVue from "bootstrap-vue";

import "bootstrap-vue/dist/bootstrap-vue.css";

import eventsView from  "../splash/events-view.vue"

import axios from "axios";

import SocialSharing from "vue-social-sharing";

import "@/element_custom_styles/index.css";

import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'

let token = document.getElementsByName("csrf-token")[0].getAttribute("content");

axios.defaults.headers.common["X-CSRF-Token"] = token;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Cache-Control"] =
  "no-cache,no-store,must-revalidate,max-age=-1,private";

Vue.prototype.$http = axios;

Vue.use(BootstrapVue);
Vue.use(SocialSharing);
Vue.use(Element, { locale })

new Vue({
  components: {
    eventsView: eventsView,
  },
  data() {
    return {
    	showNotification: window.notification
    };
  },
  mounted() {
  	if(this.showNotification){
  		this.$notify.error({
        	title: "Eventstop Access",
        	message: "You are currently unable to create or manage events using Evenstop. If you would like to access these features please contact us."
    	});
  	}
  }
}).$mount("#splashscreen");
